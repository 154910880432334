<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-6"></div>
                <div class="col-md-6 text-end">
                  <span
                    class="badge bg-warning text-dark font-size-12"
                    v-if="this.status == 'DFT'"
                    >ร่าง</span
                  >
                  <span
                    class="badge bg-success font-size-12"
                    v-if="this.status == 'SUBMIT'"
                  >
                    บันทึกแล้ว</span
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขา:</label>
                    <multiselect
                      disabled
                      id="branchId"
                      v-model="form.branchId"
                      :options="localDataBranch"
                      :class="{
                        'is-invalid': submitform && $v.form.branchId.$error,
                      }"
                      track-by="nameTh"
                      label="nameTh"
                      :show-labels="false"
                      @input="getSearch()"
                    ></multiselect>

                    <div
                      v-if="submitform && $v.form.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.branchId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="row align-items-center">
                    <div class="col-md-9 col-sm-9">
                      <div class="mb-3 position-relative">
                        <code>* </code>
                        <label for="nameTh">ชื่อลูกค้ามุ่งหวัง</label>:
                        <multiselect
                          :disabled="this.status != 'DFT'"
                          v-model="form.ppCusId"
                          :options="prospectOption"
                          label="nameTh"
                          track-by="nameTh"
                          :show-labels="false"
                          placeholder="ค้นหาชื่อลูกค้ามุ่งหวัง"
                          @search-change="getProspect($event)"
                          :class="{
                            'is-invalid': submitform && $v.form.ppCusId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.form.ppCusId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.form.ppCusId.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-3">
                      <div class="mt-2 position-relative">
                        <b-button
                          :disabled="this.status != 'DFT'"
                          class="btn"
                          variant="info"
                          v-b-modal.modalPropect
                        >
                          <i class="uil-file-search-alt"></i
                        ></b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="cusSrcId"> รุ่นรถ:</label>
                    <multiselect
                      :disabled="this.status != 'DFT'"
                      id="cusSrcId"
                      v-model="form.modelId"
                      :options="modelOption"
                      :show-labels="false"
                      label="nameTh"
                      track-by="nameTh"
                      @search-change="getModel($event)"
                      :class="{
                        'is-invalid': submitform && $v.form.modelId.$error,
                      }"
                    >
                      <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                    >
                    <div
                      v-if="submitform && $v.form.modelId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.modelId.required">{{ error }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-sm-3">
                  <div class="mb-3 position-relative">
                    <code>* </code>
                    <label for="branchId ">วันที่</label>
                    <date-picker
                      :disabled="this.status != 'DFT'"
                      v-model="form.testDriveDate"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                      :class="{
                        'is-invalid':
                          submitform && $v.form.testDriveDate.$error,
                      }"
                    ></date-picker>
                    <div
                      v-if="submitform && $v.form.testDriveDate.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.testDriveDate.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="validation">เวลา</label>
                    <date-picker
                      :disabled="this.status != 'DFT'"
                      v-model="form.testDriveTime"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      :class="{
                        'is-invalid':
                          submitform && $v.form.testDriveTime.$error,
                      }"
                    >
                    </date-picker>
                    <div
                      v-if="submitform && $v.form.testDriveTime.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.testDriveTime.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-7">
                  <div class="mb-3 position-relative">
                    <label for="">หมายเหตุ :</label>
                    <textarea
                      :disabled="this.status != 'DFT'"
                      v-model="form.note"
                      class="form-control"
                      name="textarea"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <br />

              <div class="row justify-content-end">
                <div class="col-md-auto col-sm-auto mb-3">
                  <button
                    :disabled="this.status != 'DFT'"
                    class="btn btn-success float-end"
                    type="submit"
                    @click="Form"
                  >
                    บันทึก
                  </button>
                </div>
                <div class="col-md-auto col-sm-auto mb-3">
                  <button
                    class="btn btn-danger float-end"
                    :disabled="this.status != 'DFT'"
                    @click="submit"
                  >
                    ยืนยัน
                  </button>
                </div>
              </div>
              <b-modal
                ref="modalPropect"
                id="modalPropect"
                title="รายชื่อลูกค้ามุ่งหวัง"
                hide-footer
                size="xl"
                centered
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 align-items-center">
                      <div class="row align-items-center">
                        <div class="col-sm-4 col-md-4">
                          <div class="mb-3 position-relative">
                            <label class="d-inline align-items-center">
                              สาขา:</label
                            >
                            <multiselect
                              v-model="form.branchId"
                              :options="localDataBranch"
                              label="nameTh"
                              :show-labels="false"
                            >
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-sm-4 col-md-4">
                          <label class="d-inline align-items-center m-2">
                            ชื่อลูกค้า:
                            <b-form-input
                              v-model="filter.nameThPros"
                              type="search"
                              placeholder="ชื่อ-นามสกุล"
                              class="form-control ms-2"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </label>
                        </div>
                        <div class="col-sm-4 col-md-4">
                          <label class="d-inline align-items-center m-2">
                            นามสกุลลูกค้า:
                            <b-form-input
                              v-model="filter.familyNameThPros"
                              type="search"
                              placeholder="นามสกุลลูกค้า"
                              class="form-control ms-2"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </label>
                        </div>
                        <div
                          class="col-2 col-sm-2 col-md-2 text-end"
                          style="margin-left: auto; margin-right: 0"
                        >
                          <b-button
                            class="btn btn ms-2"
                            variant="info"
                            type="submit"
                            @click="handleSearch"
                          >
                            <i class="uil-search"></i>
                            ค้นหา
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                      <b-skeleton-table
                        :rows="5"
                        :columns="6"
                        :table-props="{ bordered: false, striped: true }"
                        animation="throb"
                      ></b-skeleton-table>
                    </template>
                    <div class="row mt-2">
                      <div class="col-sm-12 col-md-12">
                        <div class="row align-items-center">
                          <div class="col-sm-5 col-md-3">
                            <div
                              id="tickets-table_length"
                              class="dataTables_length"
                            >
                              <label class="d-inline-block align-items-center">
                                แสดงผล
                                <b-form-select
                                  v-model="perPage"
                                  size="sm"
                                  :options="pageOptions"
                                  @input="handlePageChange"
                                ></b-form-select
                                >&nbsp; รายการ
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6"></div>
                        </div>
                      </div>
                    </div>

                    <div class="table-responsive mb-0">
                      <b-table
                        :items="prospectOption"
                        :fields="fields"
                        responsive="true"
                        :per-page="perPage"
                        :current-page="1"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        ref="selectableTable"
                        selectable
                        :select-mode="selectMode"
                        @row-selected="select"
                      >
                        <template #cell(level)="prospectOption">
                          <span v-if="prospectOption.item.level == '3'">
                            สูง
                          </span>
                          <span v-if="prospectOption.item.level == '2'">
                            กลาง
                          </span>
                          <span v-if="prospectOption.item.level == '1'">
                            ต่ำ
                          </span>
                          <span v-if="prospectOption.item.level == '0'">
                            อื่นๆ
                          </span>
                        </template>
                        <template
                          #cell(index)="prospectOption"
                          v-if="this.currentPage > 1"
                        >
                          {{
                            prospectOption.index +
                            1 +
                            (currentPage * perPage - perPage)
                          }}
                        </template>
                        <template #cell(index)="prospectOption" v-else>
                          {{ prospectOption.index + 1 }}
                        </template>

                        <template #cell(status)="prospectOption">
                          <span
                            class="badge bg-warning font-size-12 ms-2"
                            v-if="prospectOption.item.status === 'A'"
                          >
                            กำลังติดตาม
                          </span>

                          <span
                            class="badge bg-success font-size-12 ms-2"
                            v-if="prospectOption.item.status === 'C'"
                          >
                            เสร็จสิ้น
                          </span>
                          <span
                            class="badge bg-danger font-size-12 ms-2"
                            v-if="prospectOption.item.status === 'L'"
                          >
                            Lost
                          </span>
                        </template>
                      </b-table>
                    </div>

                    <div class="row">
                      <span
                        v-if="this.totalRecord === 0"
                        class="text-center font-size-16"
                        >ไม่พบรายการ</span
                      >
                      <div class="col" v-if="this.totalRecord > 0">
                        <div class="col">
                          หน้า {{ currentPage }} จาก
                          {{ this.totalPage }} ทั้งหมด {{ totalRecord }} รายการ
                        </div>

                        <div class="col">
                          <div
                            class="
                              dataTables_paginate
                              paging_simple_numbers
                              float-end
                            "
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRecord"
                                :per-page="perPage"
                                @change="handleChangePage"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <br />
                      <hr />
                      <br />
                    </div>
                  </b-skeleton-wrapper>
                </div>

                <div class="row">
                  <div class="col-md">
                    <button class="btn btn-success float-end">ตกลง</button>
                  </div>
                </div>
              </b-modal>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  // numeric,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // minValue,
  // maxValue,
  // url,
  // alphaNum
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.testDrive,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      overlayFlag: false,
      loading: undefined,
      err: "โปรดกรอกข้อมูลให้ถูกต้อง",
      error: "โปรดตรวจสอบข้อมูล",
      title: "แก้ไขการนัดหมายทดลองขับ",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },

        {
          text: appConfig.testDrive,
          href: "/branch-test-drive",
        },
        {
          text: "แก้ไขการนัดหมายทดลองขับ",
          active: true,
        },
      ],
      modelOption: [],
      optionsBranch: [
        {
          name: "สุริยะไอที สำนักงานใหญ่",
          id: "1",
        },
      ],
      optionsWage: [
        {
          name: "ตามชั่วโมงงาน",
          id: "HR",
        },
        {
          name: "Flat Rale",
          id: "PX",
        },
        {
          name: "ฟรีค่าแรง",
          id: "FRE",
        },
      ],
      fields: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "ppCusCode",
          sortable: true,
          label: "เลขที่ลูกค้ามุ่งหวัง",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อลูกค้า",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุลลูกค้า",
        },
        {
          key: "level",
          sortable: true,
          label: "ระดับความสนใจ",
        },
        {
          key: "cusSrcNameTh",
          sortable: true,
          label: "อาชีพ",
        },
        {
          key: "occNameTh",
          sortable: true,
          label: "แหล่งที่มา",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "เบอร์โทร",
        },
        {
          key: "branchNameTh",
          sortable: true,
          label: "สาขา",
        },
      ],
      sortBy: "age",
      sortDesc: false,
      selectMode: "multi",
      testId: this.$route.params.testId,

      filterOn: [],
      localDataBranch: [],
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      isDisabled: false,
      isDisable: false,
      options: [],
      form: {
        branchId: "",
        modelId: "",
        ppCusId: "",
        testDriveDate: "",
        testDriveTime: "",
        note: "",
      },
      filter: {
        nameThPros: "",
      },
      status: "",
      submitform: false,
      prospectOption: [],
    };
  },

  validations: {
    form: {
      branchId: {
        required,
      },
      ppCusId: { required },
      modelId: { required },
      testDriveDate: {
        required,
      },
      testDriveTime: { required },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.localData = user.userAccessBranch;

    const localDataBranch = [];
    this.localData.forEach((item) => {
      localDataBranch.push(item);
      // if (user.branchId == item.branchId) {
      //   this.form.branchId = {
      //     branchId: item.branchId,
      //     nameTh: item.nameTh,
      //   };
      // }
    });
    this.localDataBranch = localDataBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
  },
  created() {
    this.getDataShow();
  },
  methods: {
    formatTime(time) {
      const parts = time.split(":");
      return +parts[0] + ":" + parts[1];
    },
    dis(option) {
      if (option.id === "FRE") {
        this.isDisabled = true;
        this.isDisable = true;
      } else if (option.id === "PX") {
        this.isDisabled = true;
      } else if (option.id === "HR" || option == null) {
        this.isDisabled = false;
      }
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postData();
      }
    },
    select(data) {
      this.data = data[0];
      this.form.ppCusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        ppCusId: this.data.ppCusId,
      };

      this.$refs["modalPropect"].hide();
    },
    getSearch() {
      this.getModel();
      this.getProspect();
    },
    getDataShow: function () {
      this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/show", {
          params: {
            testId: atob(this.testId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };
          this.form.ppCusId = {
            nameTh: response.data.data.customerNameTh,
            ppCusId: response.data.data.ppCusId,
          };
          this.form.modelId = {
            nameTh: response.data.data.modelNameTh,
            modelId: response.data.data.modelId,
          };
          this.form.testDriveDate = response.data.data.testDriveDate;
          this.status = response.data.data.status;
          this.form.testDriveTime = this.formatTime(
            response.data.data.testDriveTime
          );

          this.form.note = response.data.data.note;
          this.getModel();
          this.getProspect();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },

    getModel: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.modelOption = response.data.data;
          // this.perPage = response.data.perPage;
          // this.from = response.data.from;
          // this.totalRecord = response.data.total;
          // this.to = response.data.to;
          // this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getProspect: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/test-drive/prospect-customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event != null ? event : this.filter.nameThPros,
            familyNameTh: this.filter.familyNameThPros,
          },
        })
        .then((response) => {
          this.prospectOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    handleSearch() {
      if (this.form.branchId === "") {
        this.localDataBranch;
      } else {
        this.localDataBranch = [this.form.branchId.branchId];
      }

      this.getProspect();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getProspect();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getProspect();
    },
    postData: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/test-drive/update", {
          testId: atob(this.testId),
          ppCusId: this.form.ppCusId.ppCusId,
          modelId: this.form.modelId.modelId,
          testDriveDate: this.form.testDriveDate,
          testDriveTime: this.form.testDriveTime,
          note: this.form.note,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              // err.message +
              // "<br>" +
              err.response.data.message,
              "<br>" + err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })

        .then(() => {
          this.overlayFlag = false;
        });
    },
    submit: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/test-drive/submit", {
          testId: atob(this.testId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "branchTestDrive" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              // err.message +
              // "<br>" +
              err.response.data.message,
              "<br>" + err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })

        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
